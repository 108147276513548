<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">정산 정보</strong>
      </div>
    </div>
    <ul class="list_fold">
      <StatementInfoLine
        v-for="(item, index) in certificateList"
        :key="index"
        :certificateItemData="item"
        :index="index"
        :docflag="docflag"
        :apprType="apprType"
        :inspectHistory="item.contractInspect"
        :isAddApprover.sync="isAddApprover"
        :isInfraStatement="isInfraStatement"
        :isCompleteStep="isCompleteStep"
      />
    </ul>
  </div>
</template>

<script>
import StatementInfoLine from "./StatementInfoLine";

export default {
  name: "StatementInfo",
  components: {
    StatementInfoLine,
  },
  props: {
    certificateList: Array,
    //inspectHistory: Array,
    isInfraStatement: Boolean,
    docflag: String,
    apprType: String,
    isCompleteStep: {
      type: Boolean,
      default: false,
    },
    isAddApprover: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
};
</script>
<style scoped>
.list_fold {
  border-top: 1px solid #222;
}
</style>
